import { useState, createContext, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { app } from "../services/FirebaseConfig";
import { Navigate } from "react-router-dom";

export const AuthEmailPasswordContext = createContext({});

export const AuthEmailPasswordProvider = ({ children }) => {
  const auth = getAuth(app);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadStorageData = () => {
      const storageUser = sessionStorage.getItem("@AuthCostaoLink:user");
      if (storageUser) {
        setUser(JSON.parse(storageUser));
      }
    };
    loadStorageData();
  }, []);

  function signInEmailPassword(email, password) {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      setUser(user);
      sessionStorage.setItem("@AuthCostaoLink:user", JSON.stringify(user)); 
    })
    .catch((error) => {
      
    });
  }

  function signUpEmailPassword(email, password) {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user);
        sessionStorage.setItem("@AuthCostaoLink:user", JSON.stringify(user));
      })
      .catch((error) => {
    
      });
  }

  function signOut() {
    sessionStorage.clear();
    setUser(null);
    return <Navigate to="/" />;
  }

  return (
    <AuthEmailPasswordContext.Provider
      value={{
        signed: !!user,
        user,
        signInEmailPassword,
        signUpEmailPassword,
        signOut,
      }}
    >
      {children}
    </AuthEmailPasswordContext.Provider>
  );
};
