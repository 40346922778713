import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthEmailPasswordContext } from "../../contexts/authEmailPassword";
import "./Login.css";

export const Login = () => {
  const { signInEmailPassword, signed: emailPasswordSigned } = useContext(AuthEmailPasswordContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleLoginWithEmailPassword(event) {
    event.preventDefault();
    const { email, password } = event.currentTarget;
    await signInEmailPassword(email.value, password.value);
    setPassword(""); 
  }

  if (!emailPasswordSigned) {
    return (
      <section className="login-body">
        <div className="powered-by"> 
          <img
            className="login-logo"
            src="https://www.costao.com.br/images/logo.png"
            alt="Logo"
          />
        </div>
        <div className="grid login-elements">
          <form onSubmit={handleLoginWithEmailPassword} className="login-form">
            <hgroup>
              <h1 className="login-title">URL Builder</h1>
              <h2>Construa URLs no padrão Costão</h2>
            </hgroup>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="loginBtn" type="submit">Entrar</button>
            
          </form>
          
        </div>

        <Link to="/ResetPassword" id="reset-password">Redefinir senha</Link>
        
      </section>
    );
  } else {
    return <Navigate to="/Home" />;
  }
};
