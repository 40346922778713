import React from 'react';

function LinkTable({ links, handleDeleteJob, copyLink, options, campaignName, campaignSegment, campaignStrategy, campaignMonth, campaignYear }) {

  return (
    <div>
      {links.length === 0 ? (
        <nav>
          <p></p>
          <p className='sem-links'>Você ainda não possui links para esta campanha</p>
          <p></p>
        </nav>
      ) : (
        <table role='grid'>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Origem</th>
              <th>Mídia</th>
              <th>Criação</th>
              <th>Info</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {links.map((link) => (
              <tr key={link.id}>
                <td>{link.name}</td>
                <td>{link.source}</td>
                <td>{link.medium}</td>
                <td>{new Intl.DateTimeFormat('pt-BR', options).format(link.created_at.toDate())}</td>
                <td>
                  <div className='grid'>
                    <p data-tooltip={link.created_by} className="tooltip-kbd">
                      <i
                        className="fas fa-user"
                      ></i>
                    </p> 
                    <p data-tooltip={link.url} className="tooltip-kbd">
                      <i
                        className="fas fa-link"
                      ></i>
                    </p>
                  </div>
                </td>
                <td className='actions'>
                  <div className='grid'>
                    <button data-tooltip="Copiar Nomenclatura" className="copy-name">
                      <i
                        onClick={() => copyLink(`${campaignName}|${link.medium}|${campaignStrategy}|${campaignSegment}|${campaignMonth || '-'}|${campaignYear|| '-'}|${link.id}`)}
                        className="fas fa-copy"
                      ></i>
                    </button>
                    <button data-tooltip="Copiar URL" className="copy-link">
                      <i
                        onClick={() => copyLink(link.final_link)}
                        className="fas fa-link"
                      ></i>
                    </button>
                    <button data-tooltip="Deletar" className="delete" disabled>
                      <i
                        onClick={() => handleDeleteJob(link.id)}
                        className="fas fa-trash"
                      ></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default LinkTable;