import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthEmailPasswordContext } from "../contexts/authEmailPassword"; // Import the new context

export const PrivateRoutes = () => {
  const { signed: emailPasswordSigned } = useContext(AuthEmailPasswordContext);

  const isSigned = emailPasswordSigned;

  return isSigned ? <Outlet /> : <Navigate to="/" />;
};

