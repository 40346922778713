import { useContext } from "react";
import { AuthEmailPasswordContext } from "../../contexts/authEmailPassword";
import React from 'react';
import "./header.css";

const Header = () => {
  const { user, signOut } = useContext(AuthEmailPasswordContext);
  function openTab() {
    window.open("https://costao.sharepoint.com/:w:/g/TIC/EaOjsRdgHENBvpsHf5GHsJYBOkxCJl_JfiomsqWJ6G-N8g?e=AgigAJ", "_blank");
    }

  return(

    <nav class="container-fluid" className="header-all-pages">
      <ul><></>
        <li>
          <img alt="Logo Costao Principal" src="../img/logo-main.png" className="logo-header"></img>
        </li>
      </ul>
      <ul>
        <li>
              <details role="list" dir="rtl">
                <summary id="user-email" >{user.email}</summary>
                <ul role="listbox">
                  <li><a onClick={() => signOut()} href="../home">Sair</a></li>
                </ul>
              </details>
        </li>
        <i className="fas fa-info" onClick={() => openTab()}></i>
      </ul>
    </nav>

    );
};

export default Header;