import React from 'react'
import "./footer.css";

const Footer = () => {  
  return(
    
    <footer className='container-fluid'> 
            {/* <small>Costão do Santinho</small> */}
    </footer>
    

    );
};
export default Footer;