import { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoutes } from ".";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { NewLink } from "../pages/NewLink";
import { NewCampaign } from "../pages/NewCampaign";
import { ResetPassword } from "../pages/ResetPassword";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/home" element={<PrivateRoutes />}>
            <Route path="/home" element={<Home />} />
          </Route>
          <Route path="/newlink" element={<PrivateRoutes />}>
            <Route path="/newlink" element={<NewLink />} />
          </Route>
          <Route path="/newcampaign" element={<PrivateRoutes />}>
            <Route path="/newcampaign" element={<NewCampaign />} />
          </Route>
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
};
