import { AuthEmailPasswordProvider } from "./contexts/authEmailPassword";
import { AppRoutes } from "./routes/routes";

export const App = () => {
  return (
      <AuthEmailPasswordProvider> 
        <AppRoutes />
      </AuthEmailPasswordProvider>
  );
};
