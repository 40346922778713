import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation  } from "react-router-dom";
import { AuthEmailPasswordContext } from "../../contexts/authEmailPassword";
import { db } from "../../services/FirebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./NewLink.css";


function cleanString(string) {
  if (!string) {
    return string;
  }

  const lowerCaseString = string.toLowerCase();
  // const cleanedString = lowerCaseString.replace(/ /g, "+");
  const encodedString = encodeURIComponent(lowerCaseString);

  const sanitizedString = encodedString.replace(/%20/g, "+");
  return sanitizedString;
}

export const NewLink = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const campaign_id = searchParams.get("campaign_id");

  var date = new Date();
  const navigate = useNavigate();
  const { user } = useContext(AuthEmailPasswordContext);
  const [userLogado, setUserLogado] = useState(null);

  useEffect(() => {
    if (user) {
      setUserLogado(user);
    }
  }, [user]);

  const [link, setLink] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [sources, setSources] = useState([]);
  const [mediuns, setMediuns] = useState([]);

  // Busca alternativas disponíveis nas opções de inserção
  async function fetchOptionsData() {
    const optionsCollectionRef = doc(db, "options", "link");
    const optionsData = await getDoc(optionsCollectionRef);
    return optionsData;
  }

  // Busca campanhas do banco de dados
  async function fetchCampaignsData() {
    const campaignList = [];
    const collec = collection(db, "campaign");
    const campaignsSnapshot = await getDocs(collec);

    campaignsSnapshot.forEach((doc) => {
      campaignList.push(doc);
    });

    return campaignList;
  }

  useEffect(() => {
    async function fetchUserData() {
      if (userLogado) {
        const userCampaignOptions = await fetchOptionsData();
        setSources(userCampaignOptions.data().source);
        setMediuns(userCampaignOptions.data().medium);

        const userCampaigns = await fetchCampaignsData();
        setCampaigns(userCampaigns);
      }
    }

    fetchUserData();
  }, [userLogado]);

  // Lida com as ações de input no form
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setLink({ ...link, [id]: value });

    if (id === "url") {
      const urlInput = document.getElementById("url");
      const isValidUrl =
        /^(https?|ftp):\/\/[^\s/$?#]*(\?[^\s]*)?(\/[^\s]*)?$/.test(value);

      if (!isValidUrl || value.indexOf("?") !== -1) {
        urlInput.setCustomValidity("URL inválida");
        urlInput.setAttribute("aria-invalid", "true");
      } else {
        urlInput.setCustomValidity("");
        urlInput.setAttribute("aria-invalid", "false");
      }
    } else if (id === "term" || id === "content") {
      const isValidInput = /^[a-zA-Z0-9 -]*$/.test(value);
      const inputElement = document.getElementById(id);

      if (!isValidInput) {
        inputElement.setCustomValidity("Conteúdo inválido");
        inputElement.setAttribute("aria-invalid", "true");
      } else {
        inputElement.setCustomValidity("");
        inputElement.setAttribute("aria-invalid", "false");
      }
    }
  };

  // Lida com o evento de criação

  const CreateNewLink = async (e) => {
    e.preventDefault();

    // const formattedContent = cleanString(link.content);
    // const formattedTerm = cleanString(link.term);
    const formattedSource = cleanString(link.source);
    const formattedMedium = cleanString(link.medium);
    const filteredCampaign = campaigns.filter(
      (campaign) => campaign.id === link.campaign
    );
    const nameOfFilteredCampaign = filteredCampaign[0].data().name;
    const formattedCampaign = cleanString(nameOfFilteredCampaign);

    // Create a new Link object
    const newLink = {
      ...link,
      created_at: date,
      created_by: userLogado.email, 
    };


    // let finalLink = link.url + "?utm_id=" + link.campaign;


    // if (formattedSource) {
    //   finalLink += `&utm_source=${formattedSource}`;
    // }
    // if (formattedMedium) {
    //   finalLink += `&utm_medium=${formattedMedium}`;
    // }
    // if (formattedCampaign) {
    //   finalLink += `&utm_campaign=${formattedCampaign}`;
    // }
    // if (formattedContent) {
    //   finalLink += `&utm_content=${formattedContent}`;
    // }
    // if (formattedTerm) {
    //   finalLink += `&utm_term=${formattedTerm}`;
    // }

    // newLink.final_link = finalLink;

    // // Save the new link data to Firestore
    // const linksCollectionRef = collection(db, "links");
    // await addDoc(linksCollectionRef, newLink);

    // Cria um documento sem a inserção no banco (Uma referência)
    const linksCollectionRef = collection(db, "links");
    const docRef = doc(linksCollectionRef); // Cria uma referência ao documento no banco de dados
    
    // Usar o id do link no banco de dados como o ID da campanha
    let finalLink = link.url + `?utm_id=${docRef.id}`;
  

    if (formattedSource) {
      finalLink += `&utm_source=${formattedSource}`;
    }
    if (formattedMedium) {
      finalLink += `&utm_medium=${formattedMedium}`;
    }
    if (formattedCampaign) {
      finalLink += `&utm_campaign=${formattedCampaign}`;
    }
    // if (formattedContent) {
    //   finalLink += `&utm_content=${formattedContent}`;
    // }
    // if (formattedTerm) {
    //   finalLink += `&utm_term=${formattedTerm}`;
    // }

    // Adiciona o documento ao banco com o link final
    await setDoc(docRef, { ...newLink, final_link: finalLink });


    // Resetar campos
    e.target.reset();
    setLink({});
    // Atualiza a página após inserção no banco de dados
    window.location.reload();
  };
  console.log(campaign_id)
  // Cancela a operação redirecionando o usuário para Home
  const handleCancel = () => {
    navigate("/Home");
  };

  return (
    <body>
      <Header />
      <main className="container">
        <nav>
          <ul>
            <hgroup>
              <h1>Novo Link</h1>
              <h2>Crie os links de suas campanhas aqui</h2>
            </hgroup>
          </ul>
        </nav>
        <form onChange={handleInput} onSubmit={CreateNewLink}>
          <label htmlFor="name">
            Nome do Link
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Nome do Link"
              required
            />
          </label>

          <label htmlFor="url">
            URL do website destino
            <input
              type="text"
              id="url"
              name="url"
              placeholder="Website URL"
              required
            />
          </label>

          <div>
  <label htmlFor="campaign">
    <i className="fas fa-stream"></i> Campanha
  </label>
  {campaign_id ? (
    <select id="campaign" required defaultValue="">
      <option value="" disabled>
        Selecione ...
      </option>
      <option value={campaign_id} key={campaign_id}>
      {campaigns.find((objectCampaign) => objectCampaign.id === campaign_id)?.data().name}
      </option>
    </select>
  ) : (
    <select id="campaign" required defaultValue="">
      <option value="" disabled>
        Selecione ...
      </option>
      {campaigns.map((objectCampaign) => (
        <option key={objectCampaign.id} value={objectCampaign.id}>
          {objectCampaign.data().name}
        </option>
      ))}
    </select>
  )}
</div>



          <div className="grid">
            <div>
              <label htmlFor="source">
                <i className="fas fa-project-diagram"></i> Origem
              </label>
              <select id="source" required defaultValue="">
                <option value="" disabled>
                  Selecione ...
                </option>
                {sources.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="medium">
                <i className="fas fa-hashtag"></i> Mídia
              </label>
              <select id="medium" required defaultValue="">
                <option value="" disabled>
                  Selecione ...
                </option>
                {mediuns.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="grid">
            <label htmlFor="term">
              <i className="fas fa-terminal"></i> Termo
              <input
                type="text"
                id="term"
                name="term"
                placeholder="Resort"
              />
            </label>

            <label htmlFor="name">
              <i className="fas fa-lightbulb"></i> Conteúdo
              <input
                type="text"
                id="content"
                name="content"
                placeholder="Praia do Santinho"
              />
            </label>
          </div> */}

          <nav>
            <div></div>
            <div className="grid form-actions">
              <button onClick={handleCancel} className="secondary">
                Cancelar
              </button>
              <button type="submit">Criar</button>
            </div>
            <div></div>
          </nav>
        </form>

        <Footer />
      </main>
    </body>
  );
};
