import React from 'react';
import { Link } from 'react-router-dom';
import { collection, doc, deleteDoc} from "firebase/firestore";
import { db } from "../../services/FirebaseConfig";
import './campaignGrid.css'
import LinkTable from '../LinkTable';

function CampaignGrid(data) {
  // Função para excluir um documento de campanha
  async function deleteJob(docId) {
    try {
      // Excluir o documento de campanha do Firestore
      const jobsCollectionRef = collection(db, "links");
      await deleteDoc(doc(jobsCollectionRef, docId));
      console.log(`Link excluído: ${docId}`);
    } catch (error) {
      console.error(`Erro ao excluir o link: ${error}`);
    }
  }

  // Função para lidar com a exclusão de um documento de campanha
  async function handleDeleteJob(docId) {
    try {
      // Chamar a função deleteJob para excluir o documento
      await deleteJob(docId);

      // Atualizar a página após a exclusão bem-sucedida
      window.location.reload();
    } catch (error) {
      // Lidar com quaisquer erros que ocorram durante a exclusão
      console.error("Erro ao excluir o link", error);
    }
  }

  // Função para copiar um link para a área de transferência
  const copyLink = (text) => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      alert('Link copiado com sucesso.');
    } catch (error) {
      console.error('Não foi possível copiar o link:', error);
    }
  };

  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    //second: '2-digit',
    // timeZoneName: 'short'
  };

  return (
    <div className="campaign-grid">
      {data.data.map((campaign) => (
        <div className="campaign-card" key={campaign.id}>
            <div className='card-header'>
            <nav>
                <div>
                    <p className='card-title'>{campaign.name}</p>
                    
                    <p className='campaign-info'>Criada em: {new Intl.DateTimeFormat('pt-BR', options).format(campaign.created_at.toDate())} por: {campaign.created_by}  
                      {/* <kbd className='copy-naming' data-tooltip="Copiar nomenclatura"><i onClick={() => copyLink(`${campaign.name}|${campaign.strategy}|${campaign.segment}|${campaign.month}|${campaign.month}`)}
                          className="fas fa-copy"
                        ></i></kbd> */}
                    </p>
                </div>
            <div className='tag'>
                    {campaign.month && campaign.year ? (
                        <kbd className='competencia'>{campaign.month} {campaign.year}</kbd>
                        ) : null}
                    <kbd className='strategy'>{campaign.strategy}</kbd>
                    <kbd className='segment'> {campaign.segment}</kbd>
                    <Link className='cria-link' to={`/NewLink?campaign_id=${campaign.id}`}>
                      <kbd>+  link</kbd>
                    </Link>
            </div>
            </nav>
           
          </div>
             
          <LinkTable
            links={campaign.links}
            handleDeleteJob={handleDeleteJob}
            copyLink={copyLink}
            options={options}
            campaignName={campaign.name}
            campaignStrategy={campaign.strategy}
            campaignSegment={campaign.segment}
            campaignMonth={campaign.month}
            campaignYear={campaign.year}
          />
        </div>
      ))}
    </div>
  );
}

export default CampaignGrid;
