import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthEmailPasswordContext } from "../../contexts/authEmailPassword";
import { db } from "../../services/FirebaseConfig";
import {
  collection,
  getDocs
} from "firebase/firestore";
import Header from "../../components/header/";
import Footer from "../../components/footer/";
import "./Home.css";
import CampaignGrid from "../../components/campaignGrid";

export const Home = () => {
  const { user } = useContext(AuthEmailPasswordContext);
  const [userLogado, setUserLogado] = useState(null);
  const [campaignsAndLinks, setCampaignsAndLinks] = useState([]);
  const [name, setName] = useState(""); 

  // Filter campaigns based on name, strategy, and segment
  const filteredCampaigns = campaignsAndLinks.filter(campaign => {
    const isNameMatch = campaign.name.toLowerCase().includes(name.toLowerCase());
    return isNameMatch;
  });

  async function getUserLinks() {
    const campaignsCollectionRef = collection(db, "campaign");
    const campaignsSnapshot = await getDocs(campaignsCollectionRef);

    const linksCollectionRef = collection(db, "links");
    const linksSnapshot = await getDocs(linksCollectionRef);

    const campaignsAndLinksData = [];

    campaignsSnapshot.forEach((campaignDoc) => {
      const campaignData = campaignDoc.data();
      campaignData.id = campaignDoc.id;

      // Filter links associated with this campaign
      campaignData.links = linksSnapshot.docs
        .filter((linkDoc) => {
          const linkData = linkDoc.data();
          return linkData.campaign === campaignData.id;
        })
        .map((filteredLinkDoc) => {
          const filteredLinkData = filteredLinkDoc.data();
          filteredLinkData.id = filteredLinkDoc.id;
          return filteredLinkData;
        });

      campaignsAndLinksData.push(campaignData);
    });

    return campaignsAndLinksData;
  }

  useEffect(() => {
    if (user) {
      setUserLogado(user);
    }
  }, [user]);

  useEffect(() => {
    async function fetchLinks() {
      if (userLogado) {
        const userLinks = await getUserLinks();
        setCampaignsAndLinks(userLinks);
      }
    }

    fetchLinks();
  }, [userLogado]);

  return (
    <body>
      <Header />
      <main className="container">
        <nav>
          <ul>
            <hgroup>
              <h1>Links</h1>
              <h2>Parametrização do Costão</h2>
            </hgroup>
          </ul>
          <ul>
            <li>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </li>
            <li>
              <Link to="/NewCampaign">
                <button id="create-new-campaign">+ Criar Campanha</button>
              </Link>
            </li>
          </ul>
        </nav>
        <CampaignGrid data={filteredCampaigns} />
      </main>
      <Footer />
    </body>
  );
};
