import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { app } from "../../services/FirebaseConfig";
import "./Login.css";

export const ResetPassword = () => {
  const auth = getAuth(app);
  const history = useNavigate();
  const [email, setEmail] = useState("");

  async function handleResetPassword(event) {
    event.preventDefault();
    const { email } = event.currentTarget;
      sendPasswordResetEmail(auth,email.value)
      .then(() => {
        alert(`Siga as instruções enviadas para ${email}`)
        history("/home") 
      })
      .catch((error) => {
        console.log(error);        
      });
    
  }
    return (
      <section className="login-body">
        <div className="grid login-elements">
          <form onSubmit={handleResetPassword} className="login-form">
            <hgroup>
              <h1 className="login-title">Redefinir Senha</h1>
            </hgroup>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="loginBtn" type="submit">Redefinir</button>
          </form>
        </div>
        <div className="powered-by">
          <img
            className="login-logo"
            src="https://www.costao.com.br/images/logo.png"
            alt="Logo"
          />
        </div>
      </section>
    );
};
