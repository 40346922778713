import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthEmailPasswordContext } from "../../contexts/authEmailPassword";
import { db } from "../../services/FirebaseConfig";
import {
  collection,
  doc,
  getDoc,
  addDoc
} from "firebase/firestore";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./NewCampaign.css";

const months = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

export const NewCampaign = () => {
  const navigate = useNavigate();
  const date = new Date();

  const { user } = useContext(AuthEmailPasswordContext);
  const [userLogado, setUserLogado] = useState(null);
  const [campaign, setCampaign] = useState({});
  const [strategy, setStrategy] = useState([]);
  const [segment, setSegment] = useState([]);

  useEffect(() => {
    if (user) {
      setUserLogado(user);
    }
  }, [user]);

 // Função para buscar as opções de campanha no banco de dados
  const fetchOptions = async () => {
    const optionsCollectionRef = doc(db, "options", "campaign");
    const optionsData = await getDoc(optionsCollectionRef);
    return optionsData;
  };

  useEffect(() => {
    async function fetchUserData() {
      if (userLogado) {
        // Buscar as opções de campanha quando o usuário estiver logado
        const userCampaignOptions = await fetchOptions();
        setStrategy(userCampaignOptions.data().strategy);
        setSegment(userCampaignOptions.data().segment);
      }
    }

    fetchUserData();
  }, [userLogado]);

  // Lidar com a entrada de dados do usuário
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setCampaign({ ...campaign, [id]: value });

    if (id === "year") {
      const isValidInput = /^(?:\d{4})?$/.test(value) || value === '';
      const inputElement = document.getElementById(id);

      if (!isValidInput) {
        inputElement.setCustomValidity("Ano inválido");
        inputElement.setAttribute("aria-invalid", "true");
      } else {
        inputElement.setCustomValidity("");
        inputElement.setAttribute("aria-invalid", "false");
      }
    }
  };

  // Função para criar uma nova campanha
  async function CreateNewCampaign(e) {
    e.preventDefault();

    const newCampaign = {
      ...campaign,
      created_at: date,
      created_by: userLogado.email,
    };

    const campaignCollectionRef = collection(db, "campaign");
    await addDoc(campaignCollectionRef, newCampaign);

    e.target.reset();
    setCampaign({});
    window.location.reload();
  }

  // Lidar com o cancelamento e redirecionar para a página inicial
  const handleCancel = () => {
    navigate('/Home');
  };

  return (
    <div>
      <Header />
      <main className="container">
        <nav>
          <ul>
            <hgroup>
              <h1>Nova Campanha</h1>
              <h2>Crie suas campanhas aqui</h2>
            </hgroup>
          </ul>
        </nav>

        <form onChange={handleInput} onSubmit={CreateNewCampaign}>
          <label htmlFor="name">
            Nome da Campanha
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Black Friday 2023"
              required
            />
          </label>

          <div className="grid">
            <div>
              <label htmlFor="strategy">
                <i className="fas fa-bullseye"></i> Estratégia
              </label>
              <select id="strategy" required defaultValue="">
                <option value="" disabled>
                  Selecione ...
                </option>
                {strategy.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="segment">
                <i className="fas fa-filter"></i> Segmentação
              </label>
              <select id="segment" required defaultValue="">
                <option value="" disabled>
                  Selecione ...
                </option>
                {segment.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <label htmlFor="obs">
            <i className="fas fa-clipboard"></i> Observações
            <input
              type="text"
              id="obs"
              name="obs"
              placeholder="Observações sobre a campanha"
            />
          </label>

          <div className="grid">
            <div>
              <label htmlFor="month">
                <i className="fas fa-calendar-days"></i> Mês
              </label>
              <select id="month" defaultValue="">
                <option value="" disabled>
                  Selecione ...
                </option>
                {months.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="year">
                <i className="fas fa-calendar"></i> Ano
                <input
                  type="text"
                  id="year"
                  name="year"
                  placeholder="Ano de vigência"
                />
              </label>
            </div>
          </div>

          <nav>
            <div></div>
            <div className="grid form-actions">
              <button onClick={handleCancel} className="secondary cancel-button">
                Cancelar
              </button>
              <button type="submit" className="submit-button">
                Criar
              </button>
            </div>
            <div></div>
          </nav>
        </form>

        <Footer />
      </main>
    </div>
  );
};

